import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import '../css/404.scss'

const NoOpenYet = () =>
{
    const history = useHistory();
    const {t} = useTranslation();

    const returnBackHome = () =>
    {
        history.push(`/`)
    }

    return <div className="function-model-page">
        <p className="function-model-title">{t('noContent')}</p>
        <p className="function-model-describute">{t('noExit')}</p>
        <p className="function-model-back" onClick={returnBackHome}>{t('backHome')}</p>
    </div>
}
export default NoOpenYet;