import React, { useState } from "react";
import { useHistory } from "react-router";
import '../css/header.scss'

import { useTranslation } from "react-i18next";
import { ReactComponent  as LogoIcon } from '../icon/svg/logo1.svg'
// import { useHistory } from "react-router-dom";


const Header = () =>
{

    const { t, i18n } = useTranslation()

    const history = useHistory();

    const [isSwitch, setIsSwitch] = useState(true);

    const menuList = [
        { name: t('tabMenuName.menu1'), path: '' },
        { name: t('tabMenuName.menu2'), path: '/prodClusters' },
        { name: t('tabMenuName.menu3'), path: '/research' },
        { name: t('tabMenuName.menu4'), path: '/partnership' },
    ];
    const changeSwitch = () =>
    {
        // let arr = window.location.pathname.split('/');
        if (isSwitch) {
            i18n.changeLanguage('cn');
            sessionStorage.setItem('lang', 'cn')
            // history.push(`/cn${arr.length > 3 ? ('/' + arr.splice(3).join('/')) : ''}`)
        } else {
            i18n.changeLanguage('en')
            sessionStorage.setItem('lang', 'en')
            // history.push(`/en${arr.length > 3 ? ('/' + arr.splice(3).join('/')) : ''}`)
        }
        setIsSwitch(!isSwitch)
    }

    const returnToPath = (path) =>
    {
        history.push(`${path}`)    
    }

    return <div className="public-header-wrap">
        <div className="header-logo d-f a-i-c" onClick={() => returnToPath('/')}>
            <LogoIcon style={{ width: '24px', height: '30px', fontWeight: 'bold' }} />
            <span className="logo_name">{ t('logoName') }</span>
        </div>
        <ul className="header-menu">
            {
                menuList.map((item, index) => {
                    return <li className="" key={'menuItem' + index} onClick={() => returnToPath(item.path)}>
                        { item.name }
                    </li>
                })
            }
        </ul>
        <div className="header-lang">
            <div className="menu"  onClick={() => returnToPath('/contactUs')}>{t('tabMenuName.menu5')}</div>
            <div className={isSwitch ? "header-lang-active-color change-lang-box" : "change-lang-box"} onClick={changeSwitch}>
                {
                    isSwitch
                    ?   <div className="header-lang-name header-lang-name-left">{t('lang')}</div>
                    :   <></>
                }
                <div className={isSwitch ? "lang-circle-green lang-circle-right" : "lang-circle-green lang-circle-left"}></div>
                {
                    !isSwitch
                    ?   <div className="header-lang-name header-lang-name-right">{t('lang')}</div>
                    :   <></>
                }
            </div>
        </div>
    </div>
}

export default Header;