import React, { useState, useEffect, useRef } from 'react';
import { debounce } from '../../frame/publicMethods'
 
const InfiniteScroll = ({ loadMore, hasMore, loader, children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const scrollWindow = useRef(null);

  
 
  const handleScroll = () => {
    const dom = document.getElementById('sf-scroll-box-wrap');
    if (dom && dom.offsetHeight > 0) {
        const rect = dom.getBoundingClientRect();
        // console.log(rect.top, '======top');
        // console.log(dom.offsetHeight, '=======scrollWindow.current.offsetHeight');
        // console.log(window.innerHeight, '=======window.innerHeight');
        if ( (0 - rect.top) > (dom.offsetHeight - window.innerHeight)) {
            setIsLoading(true);
            loadMore();
            setTimeout(() => {
                setIsLoading(false);
            }, 1500)
        }
    }
    // console.log(window.scrollY,window.innerHeight,  window.scrollY - window.innerHeight);
    // console.log(scrollWindow.current.offsetTop, scrollWindow.current.offsetHeight, scrollWindow.current.offsetTop + scrollWindow.current.offsetHeight);
    // if ( hasMore && ((window.scrollY - window.innerHeight) > (scrollWindow.current.offsetTop + scrollWindow.current.offsetHeight))) {
    //     console.log(333333333333);
    //   setIsLoading(true);
    //   loadMore();
    // } 
    // else {
    //     setIsLoading(false);
    // }
  };
  const onhandleScroll = debounce(handleScroll, 200)
 
  useEffect(() => {
    window.addEventListener('scroll', onhandleScroll);
    return () => window.removeEventListener('scroll', onhandleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, hasMore]);
 
  return (
    <div ref={scrollWindow} id="sf-scroll-box-wrap">
      {children}
        {
            isLoading && hasMore
            ?   loader
            :   <></>
        }
    </div>
  );
};
export default InfiniteScroll;