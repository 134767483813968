import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Home from '../home/home'
// import HomePage from '../pages/home/homePage'
import QualitySequence from '../pages/qualitySequence/qualitySequence'
import QualitySequencePhone from '../pages/qualitySequence/qualitySequence_phone'
import ProdClusters from '../pages/prodClusters/prodClusters'
import ProdClustersPhone from '../pages/prodClusters/prodClusters_phone'
import Research from '../pages/research/research'
import ResearchPhone from "../pages/research/research_phone";
import Partnership from '../pages/partnership/partnership'
import ContactUs from '../pages/contactUs/contactUs'

import Frame from '../frame/frame'
import { Redirect } from 'react-router';
import NotFound from '../components/404'
import Detail from "../pages/qualitySequence/detail";
import DetailPhone from "../pages/qualitySequence/detail_phone";
// import Template from "../pages/template";
import { debounce } from '../frame/publicMethods'

class FrameRouter extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            windowWidth: window.innerWidth,
        }
        this.onhandleResize = debounce(this.handleResize, 200)
    }

    handleResize = () =>
    {
        let width = window.innerWidth;
        this.setState({
            windowWidth: width,
        })
    }

    componentDidMount () 
    {
        window.addEventListener('resize', this.onhandleResize)
    }

    componentWillUnmount ()
    {
        window.removeEventListener('resize', this.onhandleResize)
    }

    render () {
        const { windowWidth } = this.state;
        return <>
            <Router basename="/">
                <Route path="/" render={({match}) => {
                    if (window.location.pathname === '/' || window.location.pathname === '/') {
                        return <Redirect to={{ pathname: '/'}} />
                    }
                }} />
                <Route path="/" render={({ match }) => {
                    let pathBase = match.path;
                    return (
                        <Router basename="/">
                            <Switch >
                                <FrameRoute exact path={pathBase + '/'} component={ windowWidth < 1300 ? QualitySequencePhone : QualitySequence} />
                                <FrameRoute exact path={pathBase + 'prodClusters'} component={windowWidth < 1300 ? ProdClustersPhone : ProdClusters} />
                                <FrameRoute exact path={pathBase + 'QualitySequence/8x4zQ1jW6S'} component={windowWidth < 1300 ? DetailPhone : Detail}/>
                                <FrameRoute exact path={pathBase + 'research'} component={windowWidth < 1300 ? ResearchPhone : Research } model="customFooter"  />
                                <FrameRoute exact path={pathBase + 'partnership'} component={Partnership} />
                                <FrameRoute exact path={pathBase + 'contactUs'} component={ContactUs} />
                                <FrameRoute component={NotFound} />
                                {/* <FrameRoute exact path={pathBase + '/homePage'} component={Home} /> */}
                            </Switch >
                        </Router>
                    )
                }} />
            </Router>
        </>   
    }
}

const FrameRoute = ({ component: Component, model, ...rest }) => (
    <Route {...rest} render={(props) => (
        
        <Frame model={model} >
            <Component {...props} />
        </Frame>
    )} />
)

export default FrameRouter;