import React from "react";
import { useTranslation } from "react-i18next";
import WidgetColumn from "../../widget/widgetColumn";
import WidgetAjax from '../../widget/WidgetAjax'
import InfiniteScroll from "./infiniteScroll";

export default class Research extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            isMore: false,
            activeModel: null,
            data: [],
            dataList: [],
            page: 0,
            hasMore: true,
            list: [
                { 
                    title: "<class 'pandas.core.frame.DataFrame'>",
                    title1: "RangeIndex: 5570 entries, 0 to 5569",
                    title2: "Data columns (total 34 columns):",
                    total: "dtypes: float64(13), int64(10), object(11)",
                    total1: "memory usage: 1.4+ MB",
                    list: [
                        { column: "name", count: "5566 non-null", dtype: "object", index: 0, },
                        { column: "brand", count: "5569 non-null", dtype: "object", index: 1 },
                        { column: "country", count: "5570 non-null", dtype: "object", index: 2 },
                        { column: "region", count: "5570 non-null", dtype: "object", index: 3 },
                        { column: "weight", count: "5570 non-null", dtype: "int64", index: 4 },
                        { column: "specification", count: "5570 non-null", dtype: "int64", index: 5 },
                        { column: "kind", count: "5570 non-null", dtype: "object", index: 6 },
                        { column: "category ", count: "5570 non-null", dtype: "object", index: 7 },
                        { column: "import", count: "5570 non-null", dtype: "object", index: 8 },
                        { column: "world", count: "5570 non-null", dtype: "object", index: 9 },
                        { column: "sweetness", count: "5570 non-null", dtype: "object", index: 10 },
                        { column: "variety", count: "5570 non-null", dtype: "object", index: 11 },
                        { column: "alcohol", count: "5570 non-null", dtype: "float64", index: 12 },
                        { column: "expiration", count: "5570 non-null", dtype: "int64", index: 13 },
                        { column: "price", count: "5570 non-null", dtype: "float64", index: 14 },
                        { column: "shopname", count: "5544 non-null", dtype: "object", index: 15 },
                        { column: "selfoperated", count: "5570 non-null", dtype: "int64", index: 16 },
                        { column: "comment_num", count: "5570 non-null", dtype: "int64", index: 17 },
                        { column: "good_num", count: "5570 non-null", dtype: "int64", index: 18 },
                        { column: "normal_num", count: "5570 non-null", dtype: "int64", index: 19 },
                        { column: "bad_num", count: "5570 non-null", dtype: "int64", index: 20 },
                        { column: "pic_num", count: "5570 non-null", dtype: "int64", index: 21 },
                        { column: "score", count: "5570 non-null", dtype: "float64", index: 22 },
                        { column: "fixed_acidity", count: "5570 non-null", dtype: "float64", index: 23 },
                        { column: "volatile_acidity", count: "5570 non-null", dtype: "float64", index: 24 },
                        { column: "citric_acid", count: "5570 non-null", dtype: "float64", index: 25 },
                        { column: "residual_sugar", count: "5570 non-null", dtype: "float64", index: 26 },
                        { column: "chlorides", count: "5570 non-null", dtype: "float64", index: 27 },
                        { column: "free_sulfur_dioxide", count: "5570 non-null", dtype: "float64", index: 28 },
                        { column: "total_sulfur_dioxide", count: "5570 non-null", dtype: "float64", index: 29 },
                        { column: "density", count: "5570 non-null", dtype: "float64", index: 30 },
                        { column: "pH", count: "5570 non-null", dtype: "float64", index: 31 },
                        { column: "sulphates", count: "5570 non-null", dtype: "float64", index: 32 },
                        { column: "quality", count: "5570 non-null", dtype: "int64", index: 33 },
                    ],
                },
            ],
            chartList: [
                // { title: 'fixed acidity', num: 'float 64', time: 'Mean 7.6, Std 1.2, Min 3.8, Max 15.9, SHAP value 6.3' },
            ],
            columns: [
                { name: 'Sample Dataset', num: "5323" }, 
                { name: 'Oversampled Dataset', num: "9600" }, 
                { name: 'Test Dataset', num: "7680" }, 
                { name: 'Training Dataset', num: "1920" }, 
                { name: 'Annotation Quantity', num: "150" }, 
                { name: 'Feature Dimensionality', num: "35" }, 
                { name: 'Number of iterations', num: "732" }, 
                { name: 'Learning Rate', num: "0.0100" }, 
                { name: 'Training Accuracy', num: "0.9125" }, 
                { name: 'Testing Accuracy', num: "0.8346" }, 
                { name: 'Precision', num: "0.8200" }, 
                { name: 'Recall', num: "0.7800" }, 
                { name: 'F1 Score', num: "0.8000" }, 
                { name: 'AUC', num: "0.9000" }, 
            ]
        }
    }

    getData = (id, pageNum) =>
    {
        const { page, data } = this.state;
        if (!id) {
            return false
        }
        let cPage = pageNum === 0 ? pageNum : page;
        cPage++;
        const onGetData = (res) =>
        {
            if (res.status === 0)
            {
                if (res.total / 10 <= cPage) {
                    this.setState({
                        hasMore: false,
                    })
                }
                if (cPage === 1) {
                    this.setState({
                        page: cPage,
                        data: res.results
                    })
                } else {
                    this.setState({
                        page: cPage,
                        data: [...data, ...res.results]
                    })
                }
            }
        }
        
        WidgetAjax.ajax({ url: '/QualityAPI/model_results', params: { model_summary_id: id, page: cPage, records: 10 }, callback: onGetData, method: 'post' })
    }

    loadMore = () =>
    {
        const {activeModel, page} = this.state;
        this.getData(activeModel, page)
    }

    loadData = () => {
        const onGetData = (res) =>
        {
            if (res.status === 0)
            {
                this.setState({
                    dataList: res.results,
                    activeModel: res.results[0][0]
                })
                this.getData(res.results[0][0], 0)
            }
        }
        
        WidgetAjax.ajax({ url: '/QualityAPI/model_info', params: {}, callback: onGetData })
    }

    setActiveModel = async (value) =>
    {
        await this.setState({
            activeModel: value,
            data: [],
            page: 0
        })
        this.getData(value, 0)
    }
    
    getColumnData = () =>
    {

        const onGetColumnData = (res) =>
        {
            if (res.status === 0)
            {
                console.log(res);
                this.setState({
                    chartList: res.results
                })
            }
        }

        WidgetAjax.ajax({ url: '/QualityAPI/feature_info', params: {}, callback: onGetColumnData })
    }
    componentDidMount ()
    {
        this.loadData();
        this.getColumnData();
        document.title = 'QS#8x4zQ1jW6S - Label Aladdin'
    }

    render ()
    {
        const { list, chartList, isMore, activeModel, dataList, columns, hasMore, data } = this.state;

        return <>
            <ComContent
                list={list}
                chartList={chartList}
                isMore={isMore}
                activeModel={activeModel}
                dataList={dataList}
                columns={columns}
                hasMore={hasMore}
                data={data}
                loadMore={this.loadMore}
                setActiveModel={this.setActiveModel}
                setIsMore={(val) => this.setState({ isMore: val })}
            />
        </>
    }
}


const ComContent = ({ list, chartList, isMore, activeModel, setActiveModel, dataList, columns, loadMore, hasMore, data, setIsMore }) =>
{

    const {t} = useTranslation();

    return <>
        
        <div className="research_header">
            <div className="r_h_b">
                <p className="r_h_t_b">{t('research.headerTitle1')}</p>
                <p className="r_h_t_s">{t('research.headerTitle2')}</p>
            </div>
        </div>
        <div className="research_contain">
            <div className="r_c_model_1 pt-40">
                <div className="r_c_model_title_1">{t('research.title1')}</div>
                <div className="r_c_model_title_2">{t('research.title1_s')}</div>
                <div className="r_c_model_content r_c_model_content_1">{t('research.content.content1')}</div>
                <div className="r_c_model_content">{t('research.content.content2')}</div>
            </div>
            <div className="r_c_model_2 pt-40">
                <div className="r_c_model_2_title">{t('research.title2')}</div>
                <div className="r_c_model_2_content mb-60">{t('research.content2.content1')}</div>
                <div className="r_c_model_2_content_box mb-60">
                    <p>{t('research.content2.infoContent')}</p>
                    <div className="r_c_model_2_content_info">
                        {
                            list.map((item, index) => {
                                return <div className="r_c_model_2_content_info_item f-1" key={'item' + index}>
                                    <div>{item.title}</div>
                                    <div>{item.title1}</div>
                                    <div>{item.title2}</div>
                                    <ul className="r_c_m_2_c_u">
                                        <li className="d-f">
                                            <div className="r_c_m_2_c_l_i_1">#</div>
                                            <div className="r_c_m_2_c_l_i_2">Column</div>
                                            <div className="r_c_m_2_c_l_i_3">Non-Null Count</div>
                                            <div className="r_c_m_2_c_l_i_4 f-1">Dtype</div>
                                        </li>
                                        <li className="d-f">
                                            <div className="r_c_m_2_c_l_i_1">--</div>
                                            <div className="r_c_m_2_c_l_i_2">-------</div>
                                            <div className="r_c_m_2_c_l_i_3">---------</div>
                                            <div className="r_c_m_2_c_l_i_4 f-1">--</div>
                                        </li>
                                        {
                                            item.list.map((childItem, childIndex) => {
                                                return  <li className="d-f" key={'citem' + childIndex}>
                                                    <div className="r_c_m_2_c_l_i_1">{childIndex}</div>
                                                    <div className="r_c_m_2_c_l_i_2">{childItem.column}</div>
                                                    <div className="r_c_m_2_c_l_i_3">{childItem.count}</div>
                                                    <div className="r_c_m_2_c_l_i_4 f-1">{childItem.dtype}</div>
                                                </li>
                                            })
                                        }
                                    </ul>
                                    <div>{item.total}</div>
                                    <div>{item.total1}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="r_c_model_2_content mb-40">{t('research.content2.content2')}</div>
                <div className="r_c_model_2_content" dangerouslySetInnerHTML={{ __html: t('research.content2.content3') }}></div>
            </div>
            <div className="r_c_model_3 r_c_model_2 pt-40">
                <div className="r_c_model_2_title">{t('research.title3')}</div>
                <div className="r_c_model_2_content mb-40">{t('research.content3.content1')}</div>
                <div className="r_c_model_2_content mb-60">{t('research.content3.content2')}</div>
                <div className="r_c_model_3_box">
                    <ul className="">
                        {
                            chartList.map((item, index) => {
                                if (index < 6) {
                                    return <li key={'item' + index}>
                                        <div className="d-f j-c-s-b a-i-c r_c_model_3_box_item_title">
                                            <span>{item[0]}</span>
                                            <span className="r_c_model_3_box_item_s">{item[1]}</span>
                                        </div>
                                        <div className="r_c_model_3_box_item_time">Mean {item[2]}, Std {item[3]}, Min {item[4]}, Max {item[5]}, Imp_value {item[6]}</div>
                                        <div className="r_c_model_3_chart_box">
                                            <WidgetColumn 
                                            key={index} 
                                            option={{ barWidth: 26,
                                                xAxisData: JSON.parse(item[7]).x.split(',') }} 
                                            width="100%" 
                                            data={JSON.parse(item[7]).y.split(',')}
                                            />
                                        </div>
                                    </li>
                                } else {
                                    if (isMore) {
                                        return <li key={'item' + index}>
                                            <div className="d-f j-c-s-b a-i-c r_c_model_3_box_item_title">
                                                <span>{item[0]}</span>
                                                <span className="r_c_model_3_box_item_s">{item[1]}</span>
                                            </div>
                                            <div className="r_c_model_3_box_item_time">Mean {item[2]}, Std {item[3]}, Min {item[4]}, Max {item[5]}, Imp_value {item[6]}</div>
                                            <div className="r_c_model_3_chart_box">
                                                <WidgetColumn 
                                                key={index} 
                                                width="100%" 
                                                option={{ barWidth: 26,
                                                    xAxisData: JSON.parse(item[7]).x.split(',') }}
                                                data={JSON.parse(item[7]).y.split(',')}
                                                 />
                                            </div>
                                        </li>
                                    } else {
                                        return ''
                                    }
                                }
                            })
                        }
                    </ul>
                    {
                        isMore
                        ?   <></>
                        :   <div className="r_c_model_3_showMore" onClick={() => setIsMore(true)}>{t('research.btnName')}</div>
                    }
                    
                </div>
            </div>
            <div className="r_c_model_4 r_c_model_2 pt-40 mb-40">
                <div className="r_c_model_2_title">{t('research.title4')}</div>
                <div className="r_c_model_2_content mb-40">{t('research.content4.content1')}</div>
                <div className="r_c_model_2_content mb-40">{t('research.content4.content2')}</div>
                <div className="r_c_model_4_box_wrap">
                    <ul className="mb-40 r_c_model_4_box">
                        {
                            dataList.map((item, index) => {
                                return <li key={'item' + index} className={activeModel === item[0] ? 'r_c_model_4_li_active' : ''} onClick={() => setActiveModel(item[0])}>
                                    <div className="r_c_model_4_ul_time">{item[1]}</div>
                                    <div className="r_c_model_4_ul_title">{item[2]}</div>
                                    {
                                        columns.map((childItem, childIndex) => {
                                            return <div key={'child' + childIndex} className="d-f j-c-s-b r_c_model_4_li_list">
                                                <div>{childItem.name}</div>
                                                <div className="r_c_model_4_li_num">{Number(item[childIndex + 3]).toLocaleString()}</div>
                                            </div>
                                        })
                                    }
                                </li>
                            })
                        }
                    </ul>
                </div>
                <div className="r_c_model_4_title2">
                    {t('research.title6')}
                </div>
                <ul className="r_c_model_4_data">
                    <InfiniteScroll
                        loadMore={loadMore}
                        hasMore={hasMore}
                        loader={<li className="r_c_model_4_data_loading" id="r_c_model_4_data_loading_item">
                            <img src={require('../../img/loading-gif-png-5.gif')} alt="" />
                        </li>}
                    >
                        {
                            data && data.length
                            ?   data.map((item, index) => {
                                    return <li key={'data' + index} className="r_c_model_4_data_item" onClick={() => window.location.href = item[5] }>
                                        <div className="r_c_model_4_data_item_title">ProductID:{item[0]}; Cluster:{item[1]}; QualityPrediction:{item[2]}; QualitySequence:{item[3]}</div>
                                        <div className="r_c_model_4_data_item_content">{item[4]}</div>
                                    </li>
                                })
                            :   <></>
                        }
                    </InfiniteScroll>
                    {/* <li className="r_c_model_4_data_loading" id="r_c_model_4_data_loading_item">
                        <Loading loading={loading} hasData={hasData} onScroll={handleScroll} />
                    </li> */}
                </ul>
            </div> 
        </div>
    </>
}