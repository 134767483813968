import React, {useEffect, useState} from "react";
// import NoOpenYet from "../../components/noOpenYet";
import { useTranslation } from "react-i18next";
// import { ReactComponent  as FooterIcon} from '../../icon/svg/logo_black.svg'
import { ReactComponent as FooterIcon} from '../../icon/svg/logo_black.svg'
import { ReactComponent as TableIcon1 } from '../../img/research/table1.svg'
import { ReactComponent as TableIcon7 } from '../../img/research/table7.svg'
import { ReactComponent as TableIcon8_1 } from '../../img/research/table8-1_word2vec.svg'
import { ReactComponent as TableIcon8_2 } from '../../img/research/table8-2_word2vec.svg'
import { ReactComponent as TableIcon8_3 } from '../../img/research/table8-3_word2vec.svg'
import { ReactComponent as TableIcon8_4 } from '../../img/research/table8-4_word2vec.svg'
import { ReactComponent as TableIcon9_1 } from '../../img/research/table9-1_BOW.svg'
import { ReactComponent as TableIcon9_2 } from '../../img/research/table9-2_BOW.svg'
import { ReactComponent as TableIcon9_3 } from '../../img/research/table9-3_BOW.svg'
import { ReactComponent as TableIcon10 } from '../../img/research/table10.svg'
import { ReactComponent as TableIcon12 } from '../../img/research/table12.svg'
import { ReactComponent as TableIcon13 } from '../../img/research/table13.svg'
import { ReactComponent as TableIcon14 } from '../../img/research/table14.svg'

const Detail = () =>
{
    const { t } = useTranslation();
    const [activeObj, setActiveObj] = useState({ active1: 0, active2: 0, active3: 0, active4: 0, active5: 0 })

    const modelList = [
        { 
            title: t('detailContent.content.model_title1'), 
            list: [
                t('detailContent.content.model_content1.content1'), 
                t('detailContent.content.model_content1.content2'),
                t('detailContent.content.model_content1.content3'),
            ],
            type: 'img_svg',
            img: <TableIcon1 style={{ height: 'auto', width: '100%' }} />,
            introduce: t('detailContent.content.model_introduce1')
        },
        { 
            title: t('detailContent.content.model_title2'), 
            list: [
                t('detailContent.content.model_content2.content1'), 
                t('detailContent.content.model_content2.content2'),
                t('detailContent.content.model_content2.content3'),
                t('detailContent.content.model_content2.content4'),
                t('detailContent.content.model_content2.content5'),
                t('detailContent.content.model_content2.content6'),
            ],
            type: 'lunbo',
            height: '255px',
            activeValue: "active1",
            lunboList: [
                { type: 'one', imgHeight: '180px', img: require('../../img/research/table2-1_describe.png'), title: t('detailContent.content.slider2.title1'), sliderBtn: t('detailContent.content.slider2.title1') },
                { type: 'one', imgHeight: '180px',  img: require('../../img/research/table2-2_head&tail.png'), title: t('detailContent.content.slider2.title2'), sliderBtn: t('detailContent.content.slider2.title2') },
            ],
            introduce: t('detailContent.content.model_introduce2')
        },
        { 
            title: t('detailContent.content.model_title3'), 
            list: [
                t('detailContent.content.model_content3.content1'), 
            ],
            type: 'lunbo',
            height: '235px',
            activeValue: "active2",
            lunboList: [
                { 
                    type: 'more', 
                    imgHeight: '160px', 
                    imgList: [
                        require('../../img/research/table3-1_displot_dis_fixed_acidity.png'),
                        require('../../img/research/table3-2_displot_dis_volatile_acidity.png'),
                        require('../../img/research/table3-3_displot_dis_density.png'),
                        require('../../img/research/table3-4_displot_dis_pH.png'),
                        require('../../img/research/table3-5_displot_dis_total_sulfur_dioxide.png'),
                        require('../../img/research/table3-6_displot_dis_free_sulfur_dioxide.png'),
                        require('../../img/research/table3-7_displot_dis_citric_acid.png'),
                        require('../../img/research/table3-8_displot_dis_chlorides.png'),
                        require('../../img/research/table3-9_displot_dis_residual_sugar.png'),
                        require('../../img/research/table3-10_displot_dis_sulphates.png'),
                        require('../../img/research/table3-11_displot_dis_alcohol.png'),
                        require('../../img/research/table3-12_displot_dis_price_ml.png'),
                        require('../../img/research/table3-13_displot_dis_score.png'),
                        require('../../img/research/table3-14_displot_dis_comment_num.png'),
                        require('../../img/research/table3-15_displot_dis_good_num.png'),
                        require('../../img/research/table3-16_displot_dis_bad_num.png'),
                        require('../../img/research/table3-17_displot_dis_normal_num.png'),
                        require('../../img/research/table3-18_displot_dis_pic_abundance.png'),
                        require('../../img/research/table3-19_displot_dis_shop_abundance.png'),
                    ], title: t('detailContent.content.slider3.title1'), sliderBtn: t('detailContent.content.slider3.title1') },
                { type: 'more', imgHeight: '160px', imgList: [
                    require('../../img/research/table4-1_boxplot_box_fixed_acidity.png'),
                    require('../../img/research/table4-2_boxplot_box_volatile_acidity.png'),
                    require('../../img/research/table4-3_boxplot_box_density.png'),
                    require('../../img/research/table4-4_boxplot_box_pH.png'),
                    require('../../img/research/table4-5_boxplot_box_total_sulfur_dioxide.png'),
                    require('../../img/research/table4-6_boxplot_box_free_sulfur_dioxide.png'),
                    require('../../img/research/table4-7_boxplot_box_citric_acid.png'),
                    require('../../img/research/table4-8_boxplot_box_chlorides.png'),
                    require('../../img/research/table4-9_boxplot_box_residual_sugar.png'),
                    require('../../img/research/table4-10_boxplot_box_sulphates.png'),
                    require('../../img/research/table4-11_boxplot_box_alcohol.png'),
                    require('../../img/research/table4-12_boxplot_box_price_ml.png'),
                    require('../../img/research/table4-13_boxplot_box_score.png'),
                    require('../../img/research/table4-14_boxplot_box_comment_num.png'),
                    require('../../img/research/table4-15_boxplot_box_good_num.png'),
                    require('../../img/research/table4-16_boxplot_box_bad_num.png'),
                    require('../../img/research/table4-17_boxplot_box_normal_num.png'),
                    require('../../img/research/table4-18_boxplot_box_pic_abundance.png'),
                    require('../../img/research/table4-19_boxplot_box_prd_abundance.png'),
                ], title: t('detailContent.content.slider3.title2'), sliderBtn: t('detailContent.content.slider3.title2') },
                { type: 'more', imgHeight: '160px', imgList: [
                    require('../../img/research/table5-1_pairplot_pair_free_sulfur_dioxide.png'),
                    require('../../img/research/table5-2_pairplot_pair_fixed_acidity.png'),
                    require('../../img/research/table5-3_pairplot_pair_comment_num.png'),
                    require('../../img/research/table5-4_pairplot_pair_price_ml.png'),
                ], title: t('detailContent.content.slider3.title3'), sliderBtn: t('detailContent.content.slider3.title3') },
                { type: 'more', imgHeight: '160px', imgList: [
                    require('../../img/research/table6-1_heat_fixed_acidity.png'),
                    require('../../img/research/table6-2_heat_heat_score.png'),
                    require('../../img/research/table6-3_heat_heat_weight.png'),
                ], title: t('detailContent.content.slider3.title4'), sliderBtn: t('detailContent.content.slider3.title4') },
            ],
            introduce: t('detailContent.content.model_introduce3')
        },
        { 
            title: '', 
            list: [
                t('detailContent.content.model_content4.content1'), 
                t('detailContent.content.model_content4.content2')
            ],
            type: 'img_svg',
            img: <TableIcon7 style={{ height: 'auto', width: '100%' }} />,
            introduce: t('detailContent.content.model_introduce4')
        },
        
        { 
            title: t('detailContent.content.model_title5'), 
            list: [
                t('detailContent.content.model_content5.content1'), 
                t('detailContent.content.model_content5.content2'),
                t('detailContent.content.model_content5.content3'),
                t('detailContent.content.model_content5.content4'),
                t('detailContent.content.model_content5.content5'),
                t('detailContent.content.model_content5.content6'),
            ],
            type: 'lunbo',
            height: '215px',
            activeValue: "active3",
            lunboList: [
                { 
                    type: 'moreSvg', 
                    imgHeight: '140px', 
                    imgList: [
                        <TableIcon8_1 style={{ height: '140px', width: '305px', marginRight: '40px' }} />,
                        <TableIcon8_2 style={{ height: '140px', width: '305px', marginRight: '40px' }} />,
                        <TableIcon8_3 style={{ height: '140px', width: '305px', marginRight: '40px' }} />,
                        <TableIcon8_4 style={{ height: '140px', width: '305px', }} />
                    ], 
                    title: t('detailContent.content.slider5.title3'), 
                    sliderBtn: t('detailContent.content.slider5.btn1') 
                },
                { 
                    type: 'moreSvg', 
                    imgHeight: '140px', 
                    imgList: [
                        <TableIcon9_1 style={{ height: '140px', width: '305px', marginRight: '40px' }} />,
                        <TableIcon9_2 style={{ height: '140px', width: '305px', marginRight: '40px' }} />,
                        <TableIcon9_3 style={{ height: '140px', width: '305px', }} />
                    ], 
                    title: t('detailContent.content.slider5.title3'), 
                    sliderBtn: t('detailContent.content.slider5.btn2') 
                },
            ],
            introduce: t('detailContent.content.model_introduce5')
        },
        {
            type: 'img_svg',
            img: <TableIcon10 style={{ height: 'auto', width: '100%' }} />,
            introduce: t('detailContent.content.model_introduce10')
        },
        { 
            title: t('detailContent.content.model_title11'), 
            list: [
                t('detailContent.content.model_content11.content1'), 
                t('detailContent.content.model_content11.content2'), 
                t('detailContent.content.model_content11.content3'), 
            ],
            type: 'lunbo',
            height: '215px',
            activeValue: "active4",
            lunboList: [
                { 
                    type: 'more', 
                    imgHeight: '160px', 
                    imgList: [
                        require('../../img/research/table11-1_cluster.png'),
                        require('../../img/research/table11-2_cluster.png'),
                        require('../../img/research/table11-3_cluster.png'),
                        require('../../img/research/table11-4_cluster.png'),
                        require('../../img/research/table11-5_cluster.png'),
                        require('../../img/research/table11-6_cluster.png'),
                        require('../../img/research/table11-7_cluster.png'),
                        require('../../img/research/table11-8_cluster.png'),
                    ], 
                    title: t('detailContent.content.slider6.title1') 
                },
            ],
            introduce: t('detailContent.content.model_introduce11')
        },
        { 
            title: t('detailContent.content.model_title6'), 
            list: [
                t('detailContent.content.model_content6.content1'), 
                t('detailContent.content.model_content6.content2')
            ],
            type: 'img_svg',
            img: <TableIcon12 style={{ height: 'auto', width: '100%' }} />,
        },

        { 
            list: [
                t('detailContent.content.model_content7.content1'), 
            ],
            type: 'img_svg',
            img: <TableIcon13 style={{ height: 'auto', width: '100%' }} />,
        },
        { 
            list: [
                t('detailContent.content.model_content8.content1'),
            ],
            type: 'img_svg',
            img: <TableIcon14 style={{ height: 'auto', width: '100%' }} />,
        },

        
        {
            type: 'lunbo',
            height: '148px',
            activeValue: "active5",
            lunboList: [
                { type: 'one', imgHeight: 'auto', isBorder: true, width: '305px', img: require('../../img/research/table15-1.png'), sliderBtn: t('detailContent.content.slider15.btn1') },
                { type: 'one', imgHeight: 'auto', isBorder: true, width: '305px', img: require('../../img/research/table15-2.png'), sliderBtn: t('detailContent.content.slider15.btn2') },
            ],
            introduce: t('detailContent.content.model_introduce15')
        },

        { 
            title: t('detailContent.content.model_title9'), 
            list: [
                t('detailContent.content.model_content9.content1'), 
                t('detailContent.content.model_content9.content2'),
                t('detailContent.content.model_content9.content3'),
                t('detailContent.content.model_content9.content4'),
            ],
        },
        
    ];
    const changeActiveValue = (props, value) =>
    {
        setActiveObj({
            ...activeObj,
            [props]: value
        })
    }

    useEffect(() => {
        document.title = 'QualityTech Research - Label Aladdin'
    }, [])

    return <>
        {/* <NoOpenYet /> */}
        <div className="sf-detail-header">
            <div className="d_h_c_b_wrap">
                <div className="d_h_c_b">
                    <div className="d_h_c_b_l_title">
                        <p>{t('detailContent.header.title1')}</p>
                        <p>{t('detailContent.header.title2')}</p>
                    </div>
                    <p className="d_h_c_b_l_content">{t('detailContent.header.content')}</p>
                </div>
            </div>
        </div>
        <div className="sf-detail-content mb-40">
            <div className="sf-detail-content-box pt-30">
                {/* <div className="d_c_t_1">
                    {t('detailContent.content.title')}
                </div> */}
                <div className="d_c_t_1" style={{ marginBottom: '20px' }}>
                    {t('detailContent.content.title3')}
                </div>
                <div className="d_c_t_s mt-40">
                    {t('detailContent.content.small_title')}
                </div>
                <div className="d_c_i">
                    {t('detailContent.content.introduce1')}
                </div>
                <div className="d_c_i">
                    {t('detailContent.content.introduce2')}
                </div>
                <div className="d_c_i">
                    {t('detailContent.content.introduce3')}
                </div>
                <div style={{ borderBottom: '1px dashed #333', paddingTop: '40px' }}></div>
                {
                    modelList.map((item, index) => {
                        return <div className="d_m_3 pt-40" key={'liMode' + index}>
                            {
                                item.title
                                ?   <div className="d_m_3_title">{item.title}</div>
                                :   <></>
                            }
                            
                            {
                                item.list && item.list.length
                                ?   <ul>
                                        {
                                            item.list.map((liItem, liIndex) => {
                                                return <li key={'li_content' + liIndex} className={liIndex === item.list.length - 1 ? 'mb-30' : ''}>
                                                    {liItem}
                                                </li>
                                            })
                                        }
                                    </ul>
                                :   <></>
                            }
                            
                            {
                                item.type === 'img_svg'
                                ?   <div className="mb-20 m-0-a">
                                    {item.img}
                                </div>
                                :   <></>
                            }
                            {
                                item.type === 'lunbo'
                                ?   <div className="d_m_3_lunbo_wrap">
                                    <div style={{ position: 'relative', overflow: "hidden", minHeight: item.height }}>
                                        <ul className="d_m_3_lunbo_box" style={{ width: item.lunboList.length * 100 + '%', marginBottom: '60px', left: -100 * activeObj[item.activeValue] + '%' }}>
                                            {
                                                item.lunboList.map((slider, sindex) => {
                                                    return  <li key={'slider' + sindex}  className="d_m_3_lunbo_item" style={{ width: `calc(100% / ${item.lunboList.length} - 10px)` }}>
                                                        {
                                                            slider.title
                                                            ?    <div className="d_m_3_lunbo_item_title">{slider.title}</div>
                                                            :   <></>
                                                        }
                                                            
                                                                
                                                                    {
                                                                        slider.type === 'one'
                                                                        ?   <div className="d_m_3_lunbo_item_img o-x-s lh-0" style={{ textAlign: 'center' }}>
                                                                                <img src={slider.img} alt="" style={{ height: slider.imgHeight, width: slider.width || null }} />
                                                                            </div>
                                                                        :   <></>
                                                                    }
                                                                    {
                                                                        slider.type === 'more'
                                                                        ?   <div className="d_m_3_lunbo_item_img o-x-s d-f lh-0">
                                                                                {
                                                                                    slider.imgList.map((imgItem, imgIndex) => {
                                                                                        return <img 
                                                                                                    src={imgItem} 
                                                                                                    alt="" 
                                                                                                    key={imgIndex}
                                                                                                    style={{ height: slider.imgHeight, marginRight: '20px' }} 
                                                                                                />
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        :   <></>
                                                                    }
                                                                    {
                                                                        slider.type === 'moreSvg'
                                                                        ?   <div className="d_m_3_lunbo_item_img o-x-s d-f lh-0">
                                                                                {
                                                                                    slider.imgList.map((imgItem, imgIndex) => {
                                                                                        return <div key={imgIndex}>{imgItem}</div>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        :   <></>
                                                                    }
                                                                    {
                                                                        
                                                                        !slider.isBorder 
                                                                        ?   <div className="b-b-1"></div>
                                                                        :   <></>
                                                                    }
                                                            </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                    {
                                        item.lunboList.length && item.lunboList.length > 1
                                        ?   <ul className="d_m_3_lunbo_btn_wrap">
                                                {
                                                    item.lunboList.map((slider, sindex) => {
                                                        return <li key={'slider' + sindex} className={ activeObj[item.activeValue] === sindex ? "d_m_3_lunbo_btn_wrap_item d_m_3_lunbo_btn_wrap_item_active" : "d_m_3_lunbo_btn_wrap_item"} onClick={() => changeActiveValue(item.activeValue, sindex)}>
                                                                <div className="d_m_3_lunbo_btn_wrap_item_border"></div>
                                                                <div className="d_m_3_lunbo_item_title">{slider.sliderBtn}</div>
                                                            </li>
                                                    })
                                                }
                                            </ul>
                                        :   <></>
                                    }
                                    
                                </div>
                                :   <></>
                            }
                            {
                                item.introduce && item.introduce !== ''
                                ?   <div className="d_m_3_introduce m-0-a">{item.introduce}</div>
                                :   <></>
                            }
                            
                        </div>
                    })
                }
            </div>
        </div>
        <div className="sf-custom-footer-wrap">
            <div>
                <FooterIcon />
                <div className="sf-custom-footer-describute">{t('footerDescribute')}</div>
            </div>
        </div>
    </>
}

export default Detail;