import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Carousel } from 'antd';

const  QualitySequence = () =>
{

    const {t} = useTranslation();
    const history = useHistory();

    const contentList = [
        {
            title: t('contentList.list1.title'),
            content: t('contentList.list1.content'),
            btnName: t('contentList.list1.btnName'),
            img: require('../../img/gackground/9.jpg'),
            position: 'right',
        },
        {
            title: t('contentList.list2.title'),
            content: t('contentList.list2.content'),
            btnName: t('contentList.list2.btnName'),
            img: require('../../img/gackground/3.jpg'),
            position: 'left',
        },
        {
            title: t('contentList.list3.title'),
            content: t('contentList.list3.content'),
            btnName: t('contentList.list3.btnName'),
            img: require('../../img/gackground/8.jpg'),
            position: 'right',
        },
        {
            title: t('contentList.list4.title'),
            content: t('contentList.list4.content'),
            btnName: t('contentList.list4.btnName'),
            img: require('../../img/gackground/10.jpg'),
            position: 'left',
        },
    ]
    const slideShowList = [require('../../img/building/1.jpg'), require('../../img/building/2.jpg'),require('../../img/building/3.jpg'),require('../../img/building/4.jpg'),require('../../img/building/5.jpg'),]

    const handleToReturn = () =>
    {
        history.push(`/research`)
    }

    useEffect(() => {
        document.title = 'Quality Sequence - Label Aladdin'
    }, [])
      
    return <div className="mode-qualitySequence-wrap">
        <div className="model-banner-box qualitySequence-banner-img">
            <div className="h-60"></div>
            <div className="qualitySequence_box_wrapper">
                <ul className="qualitySequence-banner-title">
                    <li>{t('bannerTitle.title1')}</li>
                    <li>{t('bannerTitle.title2')}</li>
                </ul>
            </div>
        </div>
        <div className="model-content-box">
            <ul className="pt-40">
                <li className="content-first-model mode-box">
                    <div className="q_c_f_w_b">
                        <p className="q_c_f_w_b-title">{t('q_fisrt_content.title')}</p>
                        <div className="diver d-f">
                            <span>{t('q_fisrt_content.title1')}</span>
                            <div className="f-1"></div>
                        </div>
                        <ul className="q_c_f_w_b-list">
                            <li><span>√</span>{t('q_fisrt_content.list.name1')}</li>
                            <li><span>√</span>{t('q_fisrt_content.list.name2')}</li>
                            <li><span>√</span>{t('q_fisrt_content.list.name4')}</li>
                            <li><span>√</span>{t('q_fisrt_content.list.name3')}</li>
                        </ul>
                        <div className="q_c_f_w_b-button" onClick={() => history.push(`/QualitySequence/8x4zQ1jW6S`)}>
                            <div>
                                <p className="q_c_f_w_b-button-big">{t('q_fisrt_content.btnName')}</p>
                                <p className="q_c_f_w_b-button-small">{t('q_fisrt_content.btnName1')}</p>
                            </div>
                        </div>
                    </div>
                </li>
                {
                    contentList && contentList.length
                    ?   contentList.map((item, index) => {
                        
                        return <li key={'li' + index} className="mode-box batch-model-box">
                            <div className="model-content-title">{item.title}</div>
                            <div className="model-content-img-left" style={{
                                background: `url(${item.img}) no-repeat center / cover`
                            }}>
                                {/* <img src={item.img} alt="es-lint want to get" /> */}
                            </div>
                            <div className="model-content-content-box f-1">
                                <div className="model-content-content-article" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                <div className="model-content-content-button" style={{ background: `url(${item.img}}) no-repeat center / cover` }} onClick={handleToReturn}>{item.btnName}</div>
                            </div>
                        </li>
                    })
                    :   <></>
                }
            </ul>
            <div className="qualitySequence-bottom-slideShow">
                <p className="qualitySequence-bottom-article1">{t('bottomSlideShow.content1')}</p>
                <p>{t('bottomSlideShow.content2')}</p>
                <div className="model-foot-content">
                    <Carousel autoplay>
                        {
                            slideShowList.map((item, index) => {
                                return <div key={index} className="model-foot-content-box">
                                <div className="model-foot-content-box" style={{ background: `url(${item}) no-repeat center / cover` }}>

                                </div>
                            </div>
                            })
                        }
                    </Carousel>
                </div>
            </div>
        </div>
    </div>
}
export default QualitySequence;