import React from "react";
import NoOpenYet from "../../components/noOpenYet";

export default class ContactUs extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {}
    }

    componentDidMount ()
    {
        document.title = 'Contact US - Label Aladdin'
    }

    render ()
    {
        return <>
            <NoOpenYet />
        </>
    }
}