import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent  as FooterIcon} from '../icon/svg/logo_white.svg'
import '../css/footer.scss'

const  Footer = () =>
{

    const {t} = useTranslation();

    return <div className="sf-footer-wrap">
        <div>
            <FooterIcon />
            <div className="sf-footer-describute">{t('footerDescribute')}</div>
        </div>
    </div>
}
export default Footer;