import React from "react";
import ReactEcharts from 'echarts-for-react';

export default class WidgetColumn extends React.Component
{
    constructor (props)
    {
        super(props)
        this.state = {}
    }

    drawCharts = (option, data) =>
    {
        let config = {
            xAxis: {
                type: 'category',
                data: option && option.xAxisData && option.xAxisData.length ? option.xAxisData : [],
                axisLabel: { 
                    show: false 
                },
                axisTick: { 
                    show: false,
                },
                min: 0,
                max: 8.5,
            },
            yAxis: {
                type: 'value',
                show: false,
            },
            grid: {
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
            },
            
            color: 'rgba(47, 239, 119, 1)',
            series: [
                {
                    data: data && data.length ? data : [],
                    type: 'bar',
                    // barWidth: option && option.barWidth ? option.barWidth : 30,
                }
            ]
        };
        return config
    }

    componentDidMount ()
    {
        this.drawCharts();
    }

    render ()
    {
        const { width = 336, height = 112, option = {}, data = [] } = this.props;
        
        let config = this.drawCharts(option, data);
        
        return <ReactEcharts ref={this.myRef} option={ config } lazyUpdate={true} style={{ width: width, height: height }}></ReactEcharts>;
    }
}